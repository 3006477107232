import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"

const Heading = styled.h1`
  padding: 0 1rem;
`
const Html = styled.div`
  padding: 0 1rem;
  a {
    color: #83b413;
  }
`
class Impressum extends React.Component {
	render() {
		const data = this.props.data.impressum.allImpressums.edges[0].node
		return (
			<Layout location={this.props.location.pathname}>
				<SEO title="Impressum" />
				<Heading>{data.title[0].text}</Heading>
				<Html>{RichText.render(data.description)}</Html>
			</Layout>
		)
	}
}
export default Impressum

export const pageQuery = graphql`
  query ImpressumQuery {
    impressum: prismic {
    allImpressums(lang: "de-at") {
      edges {
        node {
          description
          title
        }
      }
    }
  }
  }
`
